export const GROUPED = [
  {
    id: 0,
    name: 'Group 1',
    children: [
      {
        id: 1,
        name: 'Item 1.1'
      },
      {
        id: 2,
        name: 'Item 1.2'
      },
      {
        id: 6,
        name: 'Item 1.3'
      },
      {
        id: 7,
        name: 'Item 1.4'
      },
      {
        id: 16,
        name: 'Item 1.5'
      },
      {
        id: 17,
        name: 'Item 1.6'
      }
    ]
  },
  {
    id: 3,
    name: 'Group 2',
    children: [
      {
        id: 4,
        name: 'Group 2.1',
        children: [
          {
            id: 11,
            name: 'Item 2.1.1'
          },
          {
            id: 12,
            name: 'Item 2.1.2'
          },
          {
            id: 13,
            name: 'Item 2.1.3'
          },
          {
            id: 18,
            name: 'Item 2.1.1'
          },
          {
            id: 19,
            name: 'Item 2.1.2'
          },
          {
            id: 20,
            name: 'Item 2.1.3'
          }
        ]
      },
      {
        id: 5,
        name: 'Item 2.2'
      }
    ]
  },
  {
    id: 8,
    name: 'Group 3',
    children: [
      {
        id: 9,
        name: 'Item 3.1'
      },
      {
        id: 10,
        name: 'Item 3.2'
      }
    ]
  },
  {
    id: 14,
    name: 'Item 4'
  },
  {
    id: 15,
    name: 'Item 5'
  }
]

export const SIMPLE = [
  {
    id: 1,
    name: 'Item 1'
  },
  {
    id: 2,
    name: 'Item 2'
  },
  {
    id: 3,
    name: 'Item 3'
  },
  {
    id: 4,
    name: 'Item 4'
  },
  {
    id: 5,
    name: 'Item 5'
  }
]

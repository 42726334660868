<template lang="pug">
  .row
    .col-md-6
      .component-card
        .form-group
          label Multiselect
          v-switch(
            icon
            outline
            label="Enable chips"
            label-position="left"
            v-model="showChips")
          v-switch(
            icon
            outline
            label="Data source with categories"
            label-position="left"
            v-model="withCategories"
            @input="resetMultiselect")
          v-switch(
            icon
            outline
            label="Enable single select"
            label-position="left"
            v-model="single"
            @input="toggleSingleMode")
          v-switch(
            icon
            outline
            label="Enable create option hook"
            label-position="left"
            v-model="createOption")
          v-switch(
            icon
            outline
            label="Disable component"
            label-position="left"
            v-model="disabled")
          v-select(
            :disabled="disabled"
            :options="dataSource"
            :single="single"
            :chips="showChips"
            :enable-crete-option="createOption"
            placeholder="Test multiselect"
            v-model="selected")
        v-btn(@click="resetMultiselect") Unselect all
    .col-md-6
      h4 Data source
      .component-card
        | {{ dataSource }}
      h4 Selected
      .component-card
        | {{ selected }}

</template>

<script>
import { SIMPLE, GROUPED } from '@/assets/data/select-options.js'

export default {
  name: 'VSelectDemo',

  data: () => ({
    options: [],
    selected: [],
    withCategories: false,
    showChips: true,
    single: true,
    createOption: true,
    disabled: false
  }),

  methods: {
    resetMultiselect () {
      this.selected = []
    },

    toggleSingleMode (value) {
      if (value) {
        this.selected = {}
      } else {
        this.selected = []
      }
    }
  },

  computed: {
    dataSource () {
      return this.withCategories ? GROUPED : SIMPLE
    }
  }
}
</script>

<style>
</style>
